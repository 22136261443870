.filepond--credits {
    display: none;
}

.filepond--root .filepond--drop-label {
    min-height: 15.75em;
}

.filepond--drop-label {
    color: black;
}

.upload-button {
    border: 1px solid black;
    color: black;
    background-color: transparent;
    padding: 7px 25px;
    text-align: center;
    text-decoration: none;
    font-size: 16px;
    border-radius: 5px;
}