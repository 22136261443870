:root {
    --main-grey-color: rgb(118, 118, 118);
}

.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.center-form-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.form-box-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.form-box-wrapper .proceed-btm{
    margin-top: 10px;
    height: 50px;
    width: 100%;
}

.form-box-wrapper .MuiFormGroup-root{
    width: 100%;
}

.form-box-wrapper .MuiOutlinedInput-root{
    margin-bottom: 4px;
}

.content-box {
    width: 50%;
}

/* On screens that are 992px or less, set the background color to blue */
@media screen and (max-width: 992px) {
    .content-box {
        width: 70%;
    }
}

/* On screens that are 600px or less, set the background color to olive */
@media screen and (max-width: 600px) {
    .content-box {
        width: 90%;
    }
}

.novatti-logo {
    width: 18rem;
}

.loader {
    height: 30rem;

}

.onboarding-button {
    background-color: #939393;
    border: none;
    color: white;
    padding: 7px 25px;
    text-align: center;
    text-decoration: none;
    font-size: 16px;
    margin: 4px 2px;
    border-radius: 20px;
    font-weight: bold;
}

.onboarding-button-right {
    float: right;
}

.onboarding-button-left {
    float: left;
}

.onboarding-button + .onboarding-button {
    margin-left: 10px;
}

    /* Bank Account File upload section */

.upload-wrapper {
    text-align: center;
}

#form-file-upload {
    height: 16rem;
    width: 28rem;
    max-width: 100%;
    text-align: center;
    position: relative;
}

#input-file-upload {
    display: none;
}

#label-file-upload {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-width: 2px;
    border-radius: 1rem;
    border-style: dashed;
    border-color: #949494;
    background-color: #f5f5f5;
    align-content: center;
}

#label-file-upload.drag-active {
    background-color: #ffffff;
}

.upload-button:hover {
    text-decoration-line: underline;
}

#drag-file-element {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
}

.payment-fee-text {
    font-size: 0.8rem;
    color: #919191;
}

.desktop-only-left-border {
    border-left: 1px solid var(--main-grey-color);
}

@media screen and (max-width: 768px) {
    .desktop-only-left-border {
        border-left: 0 solid black;
    }
    .onboarding-button {
        width: 100%;
    }
    .onboarding-button-right {
        float: none !important;
    }
    .onboarding-button-left {
        float: none !important;
    }
    .onboarding-button + .onboarding-button {
        margin-left: 0;
    }
}

.fees-row + .fees-row {
    border-top: 0.1rem solid var(--main-grey-color);
    margin-top: 0.25rem;
    padding-top: 0.5rem;
}
